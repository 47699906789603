<!--
	Relatorio Base Stimulsoft
	Esta aplicacao abre o editor na tela de relatorios manutenção, quando o usuário clica no list e escolhe o relatório
-->
<template>
  <div id="PaineisComponente" ref="PaineisComponente">
    <!-- Modal de Inclusão ou Edição do Relatório -->
		<v-dialog
			v-if="dialog_duplicar"
			:value="true"
			persistent
			style="width: 450"
			:max-width="450"
			:retain-focus="false"
			>
				<PaineisManutencaoModal
					:dialog_duplicar.sync="dialog_duplicar"
					:dados_duplicar.sync="dados_duplicar"
					acao_duplicar="E"
					ref="PaineisManutencaoModal"
				/>
			</v-dialog>
		<div id="viewerContent" style="width: 150"></div>

  </div>
</template>

<script>

var viewer      = null;
var designer    = null;

import store_site from "../../store/store_site";
import PaineisManutencaoModal from './PaineisManutencaoModal.vue';

export default {
  name : "PaineisComponente",

  components: {
    PaineisManutencaoModal
  },

  props: ['Painel', 'detalhes_'],

  data() {
    return {
    	store_site        	: store_site,

			dialog_duplicar	  	: false,
			dados_duplicar			: null,
    }
  },


	computed: {
    painel: function() {
       	return this.Painel.conteudo_stimulsoft;
    },
  },

	mounted() {

		//window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7plofUOqPB1a4HBIXJB621mau2oiAIj+ysU7gKUXfjn/D5BocmduNB+ZMiDGPxFrAp3PoD0nYNkkWh8r7gBZ1v/JZSXGE3bQDrCQCNSy6mgby+iFAMV8/PuZ1z77U+Xz3fkpbm6MYQXYp3cQooLGLUti7k1TFWrnawT0iEEDJ2iRcU9wLqn2g9UiWesEZtKwI/UmEI2T7nv5NbgV+CHguu6QU4WWzFpIgW+3LUnKCT/vCDY+ymzgycw9A9+HFSzARiPzgOaAuQYrFDpzhXV+ZeX31AxWlnzjDWqpfluygSNPtGul5gyNt2CEoJD1Yom0VN9fvRonYsMsimkFFx2AwyVpPcs+JfVBtpPbTcZscnzUdmiIvxv8Gcin6sNSibM6in/uUKFt3bVgW/XeMYa7MLGF53kvBSwi78poUDigA2n12SmghLR0AHxyEDIgZGOTbNI33GWu7ZsPBeUdGu55R8w="

		//window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkgpgFGkUl79uxVs8X+uspx6K+tqdtOB5G1S6PFPRrlVNvMUiSiNYl724EZbrUAWwAYHlGLRbvxMviMExTh2l9xZJ2xc4K1z3ZVudRpQpuDdFq+fe0wKXSKlB6okl0hUd2ikQHfyzsAN8fJltqvGRa5LI8BFkA/f7tffwK6jzW5xYYhHxQpU3hy4fmKo/BSg6yKAoUq3yMZTG6tWeKnWcI6ftCDxEHd30EjMISNn1LCdLN0/4YmedTjM7x+0dMiI2Qif/yI+y8gmdbostOE8S2ZjrpKsgxVv2AAZPdzHEkzYSzx81RHDzZBhKRZc5mwWAmXsWBFRQol9PdSQ8BZYLqvJ4Jzrcrext+t1ZD7HE1RZPLPAqErO9eo+7Zn9Cvu5O73+b9dxhE2sRyAv9Tl1lV2WqMezWRsO55Q3LntawkPq0HvBkd9f8uVuq9zk7VKegetCDLb0wszBAs1mjWzN+ACVHiPVKIk94/QlCkj31dWCg8YTrT5btsKcLibxog7pv1+2e4yocZKWsposmcJbgG0"
		//window.Stimulsoft.StiOptions.WebServer.url = "http://localhost:30006";

		console.log('Loading Viewer view');
    
		console.log('Creating the dashboard viewer with default options');
			// Specify necessary options for the viewer
			var options = new window.Stimulsoft.Viewer.StiViewerOptions();
options.height = "100%";
options.width = "100%";

 			options.appearance.chartRenderType = 3
 			options.appearance.datePickerFirstDayOfWeek = 0
			options.appearance.fullScreenMode = false
 			options.appearance.htmlRenderMode = 3
 			options.appearance.interfaceType = 0
 			options.appearance.pageAlignment = 1
 			options.appearance.parametersPanelColumnsCount = 2
			options.appearance.parametersPanelDateFormat = "dd/MM/YYYY"
			options.appearance.parametersPanelMaxHeight = 300
 			options.appearance.parametersPanelPosition = 0
 			options.appearance.parametersPanelSortDataItems = true
 			options.appearance.reportDisplayMode = 3
 			options.appearance.rightToLeft = false
////++++ 			options.appearance.scrollbarsMode = true
 			options.appearance.showPageShadow = false
 			options.appearance.showTooltips = true
 			options.appearance.showTooltipsHelp = false
			options.toolbar.showOpenButton		= false;
			
			// O options.toolbar desta forma dá erro na versão 2023, os elementos tem de ser um a um como descritos abaixo
			// options.toolbar	= 	{ elementos }
			options.toolbar.showDesignButton 		= true;
			options.toolbar.showAboutButton 		= false;
		var viewer = new window.Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
		console.log('Creating a new dashboard instance');
	
		// var report = window.Stimulsoft.Report.StiReport.createNewDashboard();
		// console.log('Load dashboard from url');
		// report.loadFile('/dashboard/DashboardChristmas.mrt');
		// console.log('Assigning dashboard to the viewer, the dashboard will be built automatically after rendering the viewer');
		// viewer.report = report;
		// console.log('Rendering the viewer to selected element');
		// viewer.renderHtml("viewerContent");
		// console.log('Loading completed successfully!');
		
		if (this.Painel) {
			this.createViewer();
      this.setDashboard(null);
		}
		else if (this.cod_relatorio) {
			const abre_rel = async(p_cod_relatorio, p_parametros) => {
				let parametros = [];
				if (p_parametros) {
					let paramAux = null;
					paramAux = JSON.parse(p_parametros);
					if (!Array.isArray(paramAux)) {
						parametros.push(paramAux);
					}
					else {
						parametros = paramAux;
					}
				}
				parametros.push({ Name: "cod_usuario", Value: store_usuario.user.cod_usuario });
				let body = {
					cod_relatorio: p_cod_relatorio,
					tipo: "json_conteudo",
					parametros: parametros,
				};
				let lo_Rel = await Relatorio(body);
				this.relatorio_conteudo = lo_Rel;
				this.createViewer();
		    this.setDashboard(null);
			}
			abre_rel(this.cod_relatorio, this.parametros)
		}
  },

  methods: {

		seta_dialog(rel) {
			this.dados_duplicar = rel;
			this.dialog_duplicar = true;
			document.getElementById("StiDesigner").style.zIndex = "99";
		},

    createViewer() {
			// Specify necessary options for the viewer
			var options = new window.Stimulsoft.Viewer.StiViewerOptions();
////++++			options.height = "100%";
options.width = "100%";

// 			options.appearance.allowTouchZoom = true;
 			//options.appearance.backgroundColor = t {name: "White", _a: 255, _r: 255, _g: 255, _b: 255},
// 			options.appearance.bookmarksPrint = false
// 			options.appearance.bookmarksTreeWidth = 180
// 			options.appearance.chartRenderType = 3
// 			options.appearance.datePickerFirstDayOfWeek = 0
				options.appearance.fullScreenMode = false
// 			options.appearance.htmlRenderMode = 3
	 			options.appearance.interfaceType = 0
// 			options.appearance.openExportedReportWindow = "_blank"
// 			options.appearance.openLinksWindow = "_blank"
// 			options.appearance.pageAlignment = 1
 			//options.appearance.pageBorderColor = t {name: "Gray", _a: 255, _r: 128, _g: 128, _b: 128},
// 			options.appearance.parametersPanelColumnsCount = 2
	 			options.appearance.parametersPanelDateFormat = "dd/MM/YYYY"
 			// options.appearance.parametersPanelMaxHeight = 300
// 			options.appearance.parametersPanelPosition = 0
// 			options.appearance.parametersPanelSortDataItems = true
// 			options.appearance.reportDisplayMode = 3
// 			options.appearance.rightToLeft = false
////++++ 			options.appearance.scrollbarsMode = true
// 			options.appearance.showPageShadow = false
 			options.appearance.showTooltips = true
 			options.appearance.showTooltipsHelp = false
			//options.toolbar.showAboutButton 	= false;
			options.toolbar.showOpenButton		= false;
			
			// O options.toolbar desta forma dá erro na versão 2023, os elementos tem de ser um a um como descritos abaixo
			// options.toolbar	= 	{ elementos }
			options.toolbar.showDesignButton 		= this.detalhes_;
			options.toolbar.showAboutButton 		= false;
			options.toolbar.ShowSendEmailButton = true;
			//options.toolbar.viewMode				  	= 0;
											// viewMode 			: { 0: "SinglePage",
											// 						1: "Continuous",
											// 						2: "MultiplePages",
											// 						3: "OnePage",
											// 						4: "WholeReport",
											// 						5: "MultiPage"
											// 						},

			//printDestination 	: Stimulsoft.Viewer.StiPrintDestination.Direct,
			//borderColor 		: Stimulsoft.System.Drawing.Color.navy,
			//showPrintButton 	: false,
			//showViewModeButton: true,
			//zoom 				: 50;
//			}
// 			options.exports = {	showExportDialog: true,
// 								showExportToCsv: true,
// 								showExportToDbf: false,
// 								showExportToDif: false,
// 								showExportToDocument: true,
// 								showExportToExcel: false,
// 								showExportToExcel2007: true,
// 								showExportToExcelXml: false,
// 								showExportToHtml: true,
// 								showExportToHtml5: true,
// 								showExportToImageBmp: false,
// 								showExportToImageGif: false,
// 								showExportToImageJpeg: false,
// 								showExportToImageMetafile: false,
// 								showExportToImagePcx: false,
// 								showExportToImagePng: false,
// 								showExportToImageSvg: false,
// 								showExportToImageSvgz: false,
// 								showExportToImageTiff: false,
// 								showExportToMht: false,
// 								showExportToOpenDocumentCalc: true,
// 								showExportToOpenDocumentWriter: true,
// 								showExportToPdf: true,
// 								showExportToPowerPoint: true,
// 								showExportToRtf: false,
// 								showExportToSylk: false,
// 								showExportToText: true,
// 								showExportToWord2007: true,
// 								showExportToXml: false,
// 								showExportToXps: false,
// 								storeExportSettings: true,
// 							}

			// Create an instance of the viewer
			viewer = new window.Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
			var Painel_ =  this.Painel
			var seta_dialog_ = this.seta_dialog;
			// Add the design button event
			viewer.onDesignReport = function (e) {
				this.visible = false;
				//if (designer == null) {
					var optionsDesigner = new window.Stimulsoft.Designer.StiDesignerOptions();
					//optionsDesigner.appearance.fullScreenMode = false;
					//optionsDesigner.appearance.htmlRenderMode = window.Stimulsoft.Report.Export.StiHtmlExportMode.Table;
					//optionsDesigner.width  = "100%";
					//v.height = "100%";

					// Create an instance of the designer
					designer = new window.Stimulsoft.Designer.StiDesigner(optionsDesigner, "StiDesigner", false);

					// Add the exit menu item event
					designer.onExit = function (e) {
						this.visible = false;
						viewer.visible = true;
					}
					// Salvar Documento
					designer.onSaveReport = async function (args) {

						var jsonReport = args.report.saveToJsonString();
						//console.log(jsonReport);
						Painel_.conteudo_stimulsoft = jsonReport

						seta_dialog_(Painel_)
					}

					designer.renderHtml("viewerContent");
				//}
				designer.visible = true;
				designer.report = e.report;
				
			};

			viewer.renderHtml("viewerContent");

		},

		setDashboard(dashboardObject) {
			// Forcibly show process indicator
			viewer.showProcessIndicator();
			dashboardObject = this.painel;

			// Timeout need for immediate display loading report indicator
			setTimeout(function () {
				// Create a new report instance
				var dashboard = window.Stimulsoft.Report.StiReport.createNewDashboard();
				// Load reports from JSON object
				dashboard.load(dashboardObject);

				// Assign the dashboard to the viewer
				viewer.report = dashboard;
			}, 50);
		},

		// Set report button style
		setReportButton(button) {
			for (var reportName in reportsCollection) {
				var reportButton = document.getElementById(reportName);
				reportButton.isSelected = reportButton == button;
				reportButton.className = reportButton.isSelected ? "ReportButton Selected" : "ReportButton";
			}
		},

		// Load first report after the page is loaded
		// onBodyLoad() {
		// 	createViewer();
		// 	selectedButton = document.getElementById("Relatorio_31");
		// 	//console.log(selectedButton);
		// 	onButtonClick(selectedButton, Relatorio_31);
		// },

		// Demo Report button events
		// ---

		onButtonClick(button, reportObject) {
			selectedButton.className = "ReportButton";
			button.className = "ReportButton Selected";
			selectedButton = button;
			setReport(reportObject);
		},

		onButtonMouseOver(button) {
			if (button != selectedButton) button.className = "ReportButton Over";
		},

		onButtonMouseOut(button) {
			if (button != selectedButton) button.className = "ReportButton";
		}

  }

}

</script>

<style scoped>

	.div1 {
		height: calc(100vh - 75px);
		/* height: 1300px !important; */
		background-color:#FFFFFF;
	}

</style>
